const fn = () => {
  console.log('ready!')
}

fn();


svg4everybody();



$(function() {

  // back to top button

  function backToTop(scrollTrigger) {
    var scrollTop = $(window).scrollTop();
    if (scrollTop > scrollTrigger) {
        $('.back-to-top').addClass('show');
    } else {
        $('.back-to-top').removeClass('show');
    }
  }

  backToTop(500);

  $(window).on('scroll', function () {
      backToTop(500);
  });

  $('.back-to-top').click(function() {
    $('html,body').animate({
      scrollTop: 0
    }, 700);
  })


  $('.accordion__item').click(function() {
    $(this).toggleClass('collapsed');
    $(this).find('.accordion__answer').slideToggle();
  })

  $('.navbar-nav').flexMenu({
    threshold: 5,
    cutoff: 0,
    showOnHover: false,
    linkText: "Еще",
    linkTitle: "Показать еще",
    linkTextAll: "Меню",
    linkTitleAll: "Развернуть меню",
      popupClass: 'more_dropdown'
  });

  var sliderMain = $('.slider-main').bxSlider({
    auto: false,
    autoControls: false,
    adaptiveHeight: true,
    pager: true,
  });

  var sliderTestimonials = $('article.testimonials .testimonials__items').bxSlider({
    auto: false,
    autoControls: false,
    adaptiveHeight: true,
    // pager: true,
    slideMargin: 30,
    controls: false,
    pagerCustom: '#bx-pager'
  });

  if ($(window).width() < 576) {
    var sliderNews =$('.front-page .news .row').bxSlider({
      auto: true,
      autoControls: false,
      pager: true,
      controls: false,
      adaptiveHeight: true,
    });

    var sliderSpecialists = $('.front-page .specialists .row').bxSlider({
      auto: true,
      autoControls: false,
      adaptiveHeight: true,
      pager: true,
      controls: false
    });

    var sliderServices = $('.front-page .services__grid').bxSlider({
      auto: false,
      autoControls: false,
      adaptiveHeight: true,
      pager: true,
      controls: false,
      slideMargin: 15,
    });

    var sliderHww = $('.front-page .hww__list').bxSlider({
      auto: true,
      autoControls: false,
      adaptiveHeight: true,
      pager: true,
      controls: false
    });
  }


  $(window).resize(function() {

    if ($(window).width() > 576) {
      sliderNews.destroySlider();
      sliderSpecialists.destroySlider();
      sliderServices.destroySlider();
      sliderHww.destroySlider();
    } else {
      sliderNews.reloadSlider();
      sliderSpecialists.reloadSlider();
      sliderServices.reloadSlider();
      sliderHww.reloadSlider();
    }

  });


  (function() {
    var headerHeight = $('.main-header').height();
    var header = $('.main-header')
    $(window).on("scroll", function(){
      var scrollTop = $(window).scrollTop()
      if(($(window).width() > 1200)) {
        if (scrollTop > headerHeight) {
          header.addClass('fixed');
          return;
        }  else {
          header.removeClass('fixed');
        }
      }
     
      
    })
  })() 
  

});

